import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, EyeOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, List, Popconfirm, Row, Space, Table, Tabs, Tag } from "antd";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DD_MMM_YYYY, DATE_FORMAT_DD_MMM_YYYY_DAY_HH_MM } from "constants/DateConstant";
import { t } from "i18next";
import { toUpper } from "lodash";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect } from "react";
import { BiDetail } from "react-icons/bi";
import { FaFilePrescription, FaQuestion } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppointmentComplitionModal } from "utils/hooks/useAppoitmnetComplitionModal";
import { capitalizeFirstLetter, getLocalStorageItems, getYears, isValidArray } from "utils/utilities";

const AppointmentViewForDoctor = () => {
  const { doctorStore } = useMainStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showConfirm } = useAppointmentComplitionModal();

  const { doctorViewAppointmentStore, patientStore } = useMainStore();
  const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
  const { appointmentId, patientId, name: patientName, openModal = false } = state;

  const getAppointmentData = async (doctorId, appointmentId) => {
    try {
      const { setIsLoadingAppointment, getSingleAppointmentDataForDoctor, setAppointmentData } = doctorViewAppointmentStore;
      const { getUploadTestReportsForPrescriptions } = patientStore;
      getSingleAppointmentDataForDoctor({
        doctorId,
        appointmentId: appointmentId,
      })
        .then(() => {
          getUploadTestReportsForPrescriptions(patientId, appointmentId);
        })
        .finally(() => setIsLoadingAppointment());
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const { setIsLoadingAppointment, getSingleAppointmentDataForDoctor, setAppointmentData } = doctorViewAppointmentStore;
    const { getUploadTestReportsForPrescriptions } = patientStore;
    const { appointmentId, patientId } = state;
    setIsLoadingAppointment(true);
    getAppointmentData(doctorId, appointmentId);
    return () => setAppointmentData();
  }, []);

  if (openModal) {
    showConfirm(appointmentId, patientId, doctorId, 0);
  }
  const columns = [
    {
      title: t("MedicineType"),
      dataIndex: "medicineType",
      key: "medicineType",
      render: (_, { medicineType }) => <p className="mb-0 text-gray text-capitalize">{medicineType}</p>,
    },
    {
      title: t("Medicine"),
      dataIndex: "medicineName",
      key: "medicineName",
      render: (_, { medicineName }) => <p className="mb-0 text-gray text-capitalize">{medicineName}</p>,
    },
    {
      title: t("Strength"),
      dataIndex: "medicineStrength",
      key: "medicineStrength",
      render: (_, { medicineStrength }) => <p className="mb-0 text-gray text-capitalize">{medicineStrength}</p>,
    },
    {
      title: t("Medicine Dose"),
      dataIndex: "medicineDose",
      key: "medicineDose",
    },
    {
      title: t("To be Taken"),
      dataIndex: "toBeTaken",
      key: "toBeTaken",
      render: (_, { toBeTaken }) => {
        return toBeTaken == "after_food" ? "After Food" : "Before Food";
      },
    },
    {
      title: t("Medicine Time"),
      dataIndex: "medicineIntakeTime",
      key: "medicineIntakeTime",
      render: (_, { medicineIntakeTime }) => {
        return medicineIntakeTime.map((data, index) => {
          return (
            <Tag key={index} color={data == "morning" ? "blue" : data == "noon" ? "yellow" : "orange"} className="text-capitalize">
              {" "}
              {data}
            </Tag>
          );
        });
      },
    },
    {
      title: t("Intake Duration"),
      dataIndex: "intakeDuration",
      key: "intakeDuration",
    },

    {
      title: t("Note"),
      dataIndex: "note",
      key: "note",
    },
  ];
  const LabReports = ({ labData, labreportsData }) => {
    return (
      <>
        <Row className="" align="">
          <Col span={12}>
            <h4 className="d-block font-weight-bold mb-2"> {t("Lab Reports")}</h4>
            {labData.map((data, ind) => {
              return (
                data !== null && (
                  <Tag color="default" key={ind}>
                    {toUpper(data)}
                  </Tag>
                )
              );
            })}
          </Col>
          <Col span={12}>
            {isValidArray(labreportsData) && (
              <>
                <h4 className="d-block font-weight-bold mb-2"> Test Reports Uploaded by Patient</h4>
                {labreportsData.map(({ testName, report }, ind) => {
                  return (
                    <Row key={ind}>
                      <Col span={12}>{toUpper(testName)}</Col>
                      <Col>
                        <a href={report} target="_blank" color="blue">
                          <EyeOutlined className="font-size-md" />
                          <span> View</span>
                        </a>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Observer>
      {() => {
        const { isLoadingAppointment, appointmentData } = doctorViewAppointmentStore;
        const { UploadedTestReportsForPrescriptins, cancelAppointmentByPatient } = patientStore;
        const [
          {
            is_completed,
            is_canceled,
            unique_id,
            appointmentDate,
            startTime,
            preDiagnosisQuestions = [],
            testReports,
            comment,
            appointmentMode,
            appointmentType,
            paymentStatus,
            patientProfile = [{}],
            prescription = [],
          },
        ] = appointmentData;

        const [{ email, name, dob, gender, mobile_no }] = patientProfile;

        const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

        if (isLoadingAppointment) {
          return <Loading />;
        }
        const [{ medications = [], testReports: prescriptionTestReports, notes } = {}] = prescription;
        const [{ uploadedTestReports = [] } = {}] = UploadedTestReportsForPrescriptins;
        return (
          <Fragment>
            <Row justify="space-between" className="mb-2">
              <Col>
                <Button shape="circle" onClick={() => navigate(`/doctor/appointments?type=${state?.type ? state?.type : "upcoming"}`)}>
                  <ArrowLeftOutlined />
                </Button>
                <h4 className="ml-2 d-inline-block">
                  {t("Appointment")} #: {unique_id}
                </h4>
              </Col>
              <Col>
                {!is_canceled && (
                  <Space>
                    <Button type="primary" disabled={isValidArray(prescription)} ghost>
                      <Link to="/doctor/add-prescription" state={{ doctorId: doctorId, name: name, ...state }}>
                        {t("Add Prescription")}
                      </Link>
                    </Button>
                    {appointmentMode === "online" &&
                      (paymentStatus === "paid" || paymentStatus === "COMPLETED") &&
                      (state?.type ? state?.type : "upcoming") == "upcoming" &&
                      !moment.utc(startTime).local().isBefore(moment(), "day") &&
                      !is_completed && (
                        <Link
                          to="/video-consultation"
                          state={{
                            userType: 0,
                            appointmentId: appointmentId,
                            patientId,
                            doctorId,
                          }}
                        >
                          <Button type="primary" icon={<VideoCameraOutlined className="font-size-md align-middle" />}>
                            {t("Start Consultation")}
                          </Button>
                        </Link>
                      )}
                  </Space>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card className="w-100">
                  <Row className="border-bottom pb-2 " align="center" justify="space-between">
                    <Col className="d-flex align-items-center">
                      <BiDetail color="#455560" size={28} />
                      <span className="ml-2 font-weight-bolder font-size-xl text-gray pb-2">{t("Appointment Details")}</span>
                    </Col>
                    <Col className="">
                      {is_completed ? (
                        <>
                          <CheckCircleOutlined style={{ color: "green" }} className="font-size-xl mb-0" />
                          <h5 className="d-inline-block align-middle ml-1 ">{t("Appointment Completed")}</h5>
                        </>
                      ) : is_canceled ? (
                        <>
                          <CloseCircleOutlined style={{ color: "red" }} className="font-size-xl" />
                          <h5 className="d-inline-block align-middle ml-1">{t("Appointment Canceled")}</h5>
                        </>
                      ) : (
                        <Space>
                          <Popconfirm
                            title={t("Are you sure to cancel this appointment?")}
                            onConfirm={async () => {
                              await cancelAppointmentByPatient(patientId, appointmentId, true);
                              await getAppointmentData(doctorId, appointmentId);
                            }}
                            disabled={is_canceled || is_completed || state?.type === "past"}
                            okText={t("Yes")}
                            cancelText={t("No")}
                          >
                            <Button className="ml-2" type="ghost" danger disabled={is_canceled || is_completed || state?.type === "past"}>
                              {t("Cancel")}
                            </Button>
                          </Popconfirm>

                          <Button
                            onClick={async () => {
                              await showConfirm(appointmentId, patientId, doctorId, 0);
                              await getAppointmentData(doctorId, appointmentId);
                            }}
                          >
                            {t("Mark Completed")}
                          </Button>
                        </Space>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Patient Name")}</h4>
                              <p>{name}</p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Age")}</h4>
                              <p>
                                {`${getYears(moment(dob, "DD-MM-YYYY"))}`} {t("Years")}
                              </p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Email")}</h4>
                              <p>{email}</p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Gender")}</h4>
                              <p>{gender}</p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Mobile No.")}</h4>
                              <p>{mobile_no}</p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Date & Time")}</h4>
                              <p>{moment.utc(startTime).local().format(DATE_FORMAT_DD_MMM_YYYY_DAY_HH_MM)}</p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Appointment Mode")}</h4>
                              <p>{capitalizeFirstLetter(appointmentMode)}</p>
                            </Col>
                            <Col span={12}>
                              <h4 className="d-block font-weight-bold mb-1">{t("Appointment Type")}</h4>
                              <p>{capitalizeFirstLetter(appointmentType)}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="border-bottom pb-3 mt-4">
                    <Col className="d-flex align-items-center">
                      <BiDetail color="#455560" size={28} />
                      <span className="ml-2 font-weight-bolder font-size-xl text-gray">{t("Diagnosis Details")}</span>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col span={24}>
                      <h4 className="d-block font-weight-bold mb-1">{t("Symptoms")}</h4>
                      <p>{comment}</p>
                    </Col>
                  </Row>
                  {isValidArray(testReports) && (
                    <Row className="mt-3">
                      <Col span={24}>
                        <h4 className="d-block font-weight-bold mb-1">{t("Reports")}</h4>
                        <List
                          itemLayout="horizontal"
                          dataSource={testReports}
                          renderItem={(item, index) => (
                            <>
                              <Row justify="space-between" align="middle" className="py-2">
                                <Col span={6}>{item.patientName}</Col>
                                <Col span={6}>{item.report_name}</Col>
                                <Col span={6}>{moment(item.reportDate, "DD-MM-YYYY").format(DATE_FORMAT_DD_MMM_YYYY)}</Col>
                                <Col span={6} className="text-right">
                                  <Button size={"small"} target="_blank" type="primary" ghost>
                                    <Link to={item.report_url} target="_blankl">
                                      <EyeOutlined />
                                    </Link>
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card className="w-100">
                  <Row className="pb-3 border-bottom ">
                    <Col className="d-flex align-items-center">
                      <FaQuestion color="#FF00002" size={24} />
                      <span className="ml-2 font-weight-bolder font-size-md ">{t("Questions")}</span>
                    </Col>
                  </Row>
                  <Row
                    className="mt-1"
                    style={{
                      maxHeight: "575px",
                      overflowY: "scroll",
                    }}
                  >
                    {preDiagnosisQuestions.map(({ question, answer }, index) => {
                      return (
                        <Fragment key={index}>
                          <Col span={24} className="d-flex align-items-center">
                            <span size={14} className="font-weight-bold">{`Q ${index + 1}.`}</span>
                            <p className="d-inline ml-2 mb-0 font-weight-bold">{question}</p>
                          </Col>
                          <Col span={24} className="d-flex align-items-baseline">
                            <div className="">
                              <span size={14} className="font-weight-bold">
                                {t("Answer")}.
                              </span>
                            </div>{" "}
                            <p className="d-inline ml-2 mb-0 text-capitalize align-middle mb-1">{answer}</p>
                          </Col>
                        </Fragment>
                      );
                    })}
                  </Row>
                </Card>
              </Col>
            </Row>
            {isValidArray(prescription) && (
              <Row>
                <Col span={24}>
                  {/* <Card>
                                        <Row className="pb-3 border-bottom ">
                                            <Col className="d-flex align-items-center">
                                                <FaQuestion color="#FF00002" size={24} />
                                                <span className="ml-2 font-weight-bolder font-size-md ">
                                                    Prescription
                                                </span>
                                            </Col>
                                        </Row>
                                        <Table columns={columns} dataSource={medications} />
                                    </Card> */}
                  <Card>
                    <Row className="pb-3 border-bottom " justify="space-between">
                      <Col className="d-flex align-items-center">
                        <FaFilePrescription color="#FF00002" size={24} />
                        <span className="ml-2 font-weight-bolder font-size-md ">{t("Prescription")}</span>
                      </Col>
                      {isValidArray(prescription) && (
                        <Col className="d-flex align-items-center">
                          <Button type="primary" ghost>
                            <Link to="/doctor/add-prescription" state={{ doctorId: doctorId, name: name, ...state }}>
                              {t("Edit")}
                            </Link>
                          </Button>
                        </Col>
                      )}
                    </Row>
                    {isValidArray(prescription) ? (
                      <Tabs
                        defaultActiveKey="1"
                        items={[
                          {
                            key: "1",
                            label: t("Medication"),
                            children: (
                              <>
                                {isValidArray(medications) && medications.length > 0 ? <Table columns={columns} dataSource={medications} /> : <Empty description="No Data Available for Medication" />}
                              </>
                            ),
                          },
                          {
                            key: "2",
                            label: t("Notes"),
                            children: <>{notes && notes.length > 0 ? notes : <Empty description="No Notes For Prescription" />}</>,
                          },
                          {
                            key: "3",
                            label: t("Reports"),
                            children: (
                              <>
                                {isValidArray(prescriptionTestReports) && prescriptionTestReports.length > 0 ? (
                                  <LabReports labData={prescriptionTestReports} labreportsData={uploadedTestReports} />
                                ) : (
                                  <Empty description="No Data Available for Labs" />
                                )}
                              </>
                            ),
                          },
                        ]}
                        // onChange={onChange}
                      />
                    ) : (
                      <Empty description="No Prescription Available for this Appointment" className="mt-3" />
                    )}
                    {/* <Table columns={columns} dataSource={data} /> */}
                  </Card>
                </Col>
              </Row>
            )}
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default AppointmentViewForDoctor;
