import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useLocation, useNavigate } from "react-router";
import { Link, Outlet } from "react-router-dom";

import { Grid, Layout } from "antd";
import Footer from "components/layout-components/Footer";
import HeaderNav from "components/layout-components/HeaderNav";
import MobileNav from "components/layout-components/MobileNav";
import SideNav from "components/layout-components/SideNav";
import Loading from "components/shared-components/Loading";
import { DIR_LTR, DIR_RTL, NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import { Suspense, useEffect, useState } from "react";
import utils from "utils";

import { doctorNavigationConfig, patientNavigationConfig } from "configs/NavigationConfig";
import { useDispatch, useSelector } from "react-redux";

import Icon from "components/util-components/Icon";
import IntlMessage from "components/util-components/IntlMessage";
import { onMobileNavToggle } from "store/slices/themeSlice";
import { TYPE_DOCTOR, TYPE_DOCTOR_VALUE, TYPE_PATIENT, TYPE_PATIENT_VALUE, getLocalStorageItems, isValidArray } from "utils/utilities";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const MainLayout = () => {
  const navigate = useNavigate();
  const { loginStore, globalStore, doctorProfileStore, patientProfileStore } = useMainStore();

  const location = useLocation();

  const navType = useSelector((state) => state.theme.navType);
  const navCollapsed = useSelector((state) => state.theme.navCollapsed);
  const direction = useSelector((state) => state.theme.direction);
  const urlUserType = location.pathname.split("/")[1];

  const [currentRouteInfo, setCurrentRouteInfo] = useState(null);

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("xl");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  useEffect(() => {
    if (urlUserType == "patient") {
      const routeInfo = utils.getRouteInfo(patientNavigationConfig, location.pathname);
      setCurrentRouteInfo(routeInfo);
    }
    if (urlUserType == "doctor") {
      const routeInfo = utils.getRouteInfo(doctorNavigationConfig, location.pathname);
      setCurrentRouteInfo(routeInfo);
    }
  }, [location]);

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const setLocale = (localeKey, isLocaleOn = true) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());

  const MenuItem = ({ title, icon, path }) => {
    const dispatch = useDispatch();

    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("xl");

    const closeMobileNav = () => {
      if (isMobile) {
        dispatch(onMobileNavToggle(false));
      }
    };

    return (
      <>
        {icon && <Icon type={icon} />}
        <span>{setLocale(title)}</span>
        {path && <Link onClick={closeMobileNav} to={path} />}
      </>
    );
  };

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  const getSideNavMenuItem = (navItem) => {
    return navItem.map((nav) => {
      return {
        key: nav.key,
        label: <MenuItem title={nav.title} {...(nav.isGroupTitle ? {} : { path: nav.path, icon: nav.icon })} />,
        ...(nav.isGroupTitle ? { type: "group" } : {}),
        ...(nav.submenu.length > 0 ? { children: getSideNavMenuItem(nav.submenu) } : {}),
      };
    });
  };

  useEffect(() => {
    const { checkForValidUserAndType, getUserType, setUserNavigation } = loginStore;
    const { getUserDetailsById, setIsLoadingUserData, setHeaderNavProfileDetail } = globalStore;
    const { user_id, userType } = getLocalStorageItems({ getAll: true, keys: ["user_id", "userType"] });
    const { getDoctorProfileData, setIsLoadingProfileData } = doctorProfileStore;
    const { getPatientDetail, setIsLoadingPatientProfileData } = patientProfileStore;

    setIsLoadingUserData(true);
    getUserDetailsById(user_id).finally(() => setIsLoadingUserData(false));

    const fetchUserTypeData = async () => {
      const { success, data } = await checkForValidUserAndType(user_id, userType);

      const [{ name, type }] = data;

      if (name !== urlUserType) return navigate("/");

      if (name === TYPE_DOCTOR && type === TYPE_DOCTOR_VALUE) {
        getDoctorProfileData(user_id)
          .then(({ success, data }) => {
            if (success && isValidArray(data)) {
              const [{ name, degrees, profile_pic }] = data;
              setHeaderNavProfileDetail({
                userName: name,
                userDegree: degrees,
                userProfile: profile_pic,
              });
              return;
            }
            setHeaderNavProfileDetail();
          })
          .finally(() => setIsLoadingProfileData(false));
        setUserNavigation(getSideNavMenuItem(doctorNavigationConfig));
        return;
      }

      if (name === TYPE_PATIENT && type === TYPE_PATIENT_VALUE) {
        setIsLoadingPatientProfileData(true);
        getPatientDetail(user_id)
          .then(({ success, data }) => {
            if (success && isValidArray(data)) {
              const [{ name, degrees, profile_pic }] = data;
              setHeaderNavProfileDetail({
                userName: name,
                userDegree: [],
                userProfile: profile_pic,
              });
              setIsLoadingPatientProfileData(false);
              return;
            }
            setHeaderNavProfileDetail();
          })
          .finally(() => {
            setIsLoadingPatientProfileData(false);
          });
        setUserNavigation(getSideNavMenuItem(patientNavigationConfig));
        return;
      }
    };

    fetchUserTypeData();
  }, [location.pathname]);

  return (
    <>
      <Observer>
        {() => {
          const { isValidUserAndType } = loginStore;
          if (!isValidUserAndType) {
            return <Loading />;
          }

          return (
            <>
              <Layout>
                <HeaderNav isMobile={isMobile} />
                <Layout className="app-container">
                  {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
                  <Layout className="app-layout" style={getLayoutDirectionGutter()}>
                    <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
                      <Content>
                        <Suspense fallback={<Loading cover="content" />}>
                          <Outlet />
                        </Suspense>
                      </Content>
                    </div>
                    <Footer />
                  </Layout>
                </Layout>
                {isMobile && <MobileNav />}
              </Layout>
            </>
          );
        }}
      </Observer>
    </>
  );
};

export default MainLayout;
