import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Spin, Table } from "antd";
import { DATE_FORMAT_DD_MMM_YYYY } from "constants/DateConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";
import UploadLabReports from "../component/UploadLabReports";

const AddLabReports = () => {
  const { patientStore } = useMainStore();
  const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

  useEffect(() => {
    const { getLabReports, setIsLoadingForAllLabReports } = patientStore;
    setIsLoadingForAllLabReports(true);
    getLabReports(patientId).finally(() => {
      setIsLoadingForAllLabReports();
    });
  }, []);

  const handelDeleteLabReports = async (reportId, patientId) => {
    try {
      const { deleteLabReports, getLabReports } = patientStore;
      await deleteLabReports(reportId, patientId).then((res) => {
        getLabReports(patientId);
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    {
      title: t("Patient Name"),
      dataIndex: "patientName",
      key: "patientName",
    },
    {
      title: t("Report Name"),
      dataIndex: "reportName",
      key: "reportName",
    },
    {
      title: t("Report Date"),
      dataIndex: "reportDate",
      key: "reportDate",
      render: (_, { reportDate }) => <p className="mb-0 text-capitalize text-gray">{moment.utc(reportDate).local().format(DATE_FORMAT_DD_MMM_YYYY)}</p>,
    },

    {
      title: <Row justify="center">{t("Actions")}</Row>,
      key: "view",
      render: (_, { reportUrl, report__id }) => (
        <>
          <Row justify="center" gutter={16}>
            <Space>
              <Button className="tabel_icon" type="primary" ghost>
                <Link to={reportUrl} target="_blank">
                  <EyeOutlined />
                </Link>
              </Button>
              <Popconfirm
                title={t("Are you sure you want to delete?")}
                description=""
                placement="topRight"
                onConfirm={async () => {
                  await handelDeleteLabReports(report__id, patientId);
                }}
                okText={t("Yes")}
                cancelText={t("No")}
                onOpenChange={() => console.log("open change")}
              >
                <Button className="tabel_icon" type="ghost" danger>
                  <DeleteOutlined className="text-danger" />
                </Button>
              </Popconfirm>
            </Space>
          </Row>
        </>
      ),
    },
  ];

  return (
    <Observer>
      {() => {
        const { setIsOpenModalForAddLab, allLabReports, isLoadingForAllLabReports } = patientStore;
        {
          /* if (isLoadingForAllLabReports && !isValidArray(allLabReports)) {
                    return <Loading />;
                } */
        }
        const [{ reports = [] } = {}] = allLabReports;
        return (
          <Spin spinning={isLoadingForAllLabReports && !isValidArray(allLabReports)}>
            <Row justify="space-between" gutter={[16, 16]} className="mb-2">
              <h2 className="mb-0">{t("Lab Reports")}</h2>
              <Button type="primary" onClick={() => setIsOpenModalForAddLab(true)}>
                {t("Add Report")}
              </Button>
            </Row>
            <Table
              columns={columns}
              dataSource={reports.map((item, ind) => ({
                ...item,
                key: ind,
              }))}
            />
            <UploadLabReports />
          </Spin>
        );
      }}
    </Observer>
  );
};

export default AddLabReports;
