import { EyeOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, List, Popconfirm, Row, Space, Tag, Tooltip, Typography } from "antd";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getLocalStorageItems, isValidArray } from "utils/utilities";

const UpcomingAppointments = () => {
  const { doctorStore } = useMainStore();
  const [doctorId] = useState(() => {
    const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });
    return doctorId;
  });

  const navigate = useNavigate();

  return (
    <Observer>
      {() => {
        const { upcomingAppointmentsList, isLoadingOfListOfAppointmentByDoctor } = doctorStore;

        if (!isLoadingOfListOfAppointmentByDoctor && !isValidArray(upcomingAppointmentsList)) {
          return <Empty description={<Typography.Text>No upcoming appointments.</Typography.Text>} />;
        }

        return upcomingAppointmentsList.map((data) => {
          return Object.entries(data)
            .slice()
            .reverse()
            .map(([year, appointmentData]) => {
              return (
                <Fragment key={year}>
                  <p className="pl-4 font-weight-bold">{year}</p>
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={false}
                    dataSource={appointmentData}
                    renderItem={(item) => {
                      const { is_canceled, patientProfile, startTime, appointmentMode, appointmentType, patient_id, appointment_id, paymentMode, is_completed, paymentStatus, time, unique_id } = item;
                      return (
                        <>
                          <List.Item key={item.appointment_id}>
                            <Card className="position-relative">
                              {is_canceled && (
                                <Tag className="position-absolute cancel-tag rounded-0 text-uppercase" color="#FF6B72" size="large">
                                  Canceled
                                </Tag>
                              )}
                              <Row className="d-flex  justify-content-between">
                                <Col className="appointment_date px-3">
                                  <h1 className="mb-0">{moment.utc(startTime).local().format("DD")}</h1>
                                  <h5>{moment.utc(startTime).local().format("MMM")}</h5>
                                </Col>
                                <Col className="">
                                  <h4 className="mb-1">{t("Time")}</h4>
                                  <p className="">{time}</p>
                                </Col>
                                <Col span={2}>
                                  <h4 className="mb-1">{t("Name")}</h4>
                                  <p className="">{patientProfile.name}</p>
                                </Col>
                                <Col className="">
                                  <h4 className="mb-1">{t("Gender")}</h4>
                                  <p className="">{capitalizeFirstLetter(patientProfile.gender)}</p>
                                </Col>
                                <Col className="">
                                  <h4 className="mb-1">{t("Appointment Mode")}</h4>
                                  <Tag bordered={false} color={appointmentMode !== "offline" ? "blue" : "cyan"}>
                                    {appointmentMode === "offline" ? "Physical" : "Virtual"}
                                  </Tag>
                                </Col>
                                <Col className="">
                                  <h4 className="mb-1">{t("Appointment Type")}</h4>
                                  <p className="">{capitalizeFirstLetter(appointmentType)}</p>
                                </Col>
                                <Col className="">
                                  <h4 className="mb-1">{t("Payment Status")}</h4>
                                  <Tag
                                    color={`${
                                      paymentStatus == "paid" || paymentStatus == "COMPLETED" || appointmentMode == "offline" ? "#6FCE3F" : paymentStatus == "pending" ? "#FBBF45" : "#FF6D6D"
                                    }`}
                                  >
                                    {paymentStatus == "paid" || paymentStatus == "COMPLETED" ? t("PAID") : appointmentMode == "offline" ? t("PAY IN CLINIC") : t("PENDING")}
                                  </Tag>
                                </Col>
                                <Col className="d-flex align-items-center">
                                  <Space size="middle" className="d-flex justify-content-center">
                                    <Tooltip placement="top" title={t("View Details")}>
                                      <Button className="tabel_icon" type="primary" ghost>
                                        <Link
                                          to={`/doctor/view-appointment`}
                                          state={{
                                            patientId: patient_id,
                                            appointmentId: appointment_id,
                                            name: patientProfile.name,
                                            type: "upcoming",
                                          }}
                                        >
                                          <EyeOutlined />
                                        </Link>
                                      </Button>
                                    </Tooltip>
                                    {/* {appointmentMode === "online" && ( */}
                                    <Tooltip placement="top" title={appointmentMode === "offline" ? "" : is_completed ? "" : is_canceled ? "" : t("Join consultation")}>
                                      <Popconfirm
                                        title={t("Are you sure you want to join this consultation?")}
                                        description="Open Popconfirm with Promise"
                                        placement="topRight"
                                        onConfirm={() => {
                                          navigate("/video-consultation", {
                                            state: {
                                              userType: 0,
                                              appointmentId: appointment_id,
                                              patientId: patient_id,
                                              doctorId,
                                            },
                                          });
                                        }}
                                        disabled={appointmentMode === "offline" ? true : is_completed ? true : is_canceled ? true : false}
                                        okText={t("Yes")}
                                        cancelText={t("No")}
                                        onOpenChange={() => console.log("open change")}
                                      >
                                        <Button className="tabel_icon" type="primary" disabled={appointmentMode === "offline" ? true : is_completed ? true : is_canceled ? true : false}>
                                          <VideoCameraOutlined />
                                        </Button>
                                      </Popconfirm>
                                    </Tooltip>
                                    {/* )} */}
                                  </Space>
                                </Col>
                              </Row>
                            </Card>
                          </List.Item>
                        </>
                      );
                    }}
                  />
                </Fragment>
              );
            });
        });
      }}
    </Observer>
  );
};

export default UpcomingAppointments;
