import {
  BOOKING_AN_APPTIMENT,
  CANCEL_APPOINTMENT_SLOT_BY_PATIENT,
  GET_ALL_LAB_REPORTS,
  GET_APPOINTMENT_CONFIRMATION_STATUS,
  GET_APPOINTMENT_DETAILS,
  GET_APPOINTMENT_LIST,
  GET_APPOINTMNET_FEES_DETAILS,
  GET_DIAGNOSIS_QUESTIONS,
  GET_DOCTOR_AVAILABLE_SLOTS,
  GET_DOCTOR_BY_SPECIALITY,
  GET_DOCTOR_DETAILS_DOCTOR_ID,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_PARTNERS,
  GET_UPDATE_LAB_REPORTS,
  GET_UPLOADED_TEST_REPORTS_FOR_PRESCRIPTION,
  GET_URL_FOR_UPLOADADE_DOCUMENT,
  PATIENT_DELETE_LAB_REPORTS,
  UPDATE_APPOINTMENT_CONFIRM_STATUS_ZERO,
  UPDATE_APPOINTMENT_SLOT,
  UPLOAD_TEST_REPORTS_BY_PATIENT,
} from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import { getRequest, patchRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray, isValidObject } from "utils/utilities";

class PatientStore {
  defaultDate = moment();
  allSpecialityDoctors = [];
  doctorProfileDataForPatient = [{}];
  doctorAvailableSlots = [{}];
  isSelectedDateByCalender = moment(); // for card title in slots booking
  isDoctorDiagnosisQuestion = [{}];
  isAppointmentSteps = {
    steps: 0,
    isSuccess: false,
  };
  isAppointmentFormData = {};
  appointmentFeesDetails = [{}];
  appointmentBookingConfermation = [{}];
  listOfAppointmentUpcoming = [];
  listOfAppointmentPast = [];
  patientAppointmentDetails = [{}];
  getSlotsByTime = "morningSlots";
  isConfirmingBooking = false;
  isuploadedTestReportsFileList = [];
  UploadedTestReportsForPrescriptins = [];
  doctorDetails = [];
  isOpenModalForPriscriptionDocument = false;
  isOpenModalForAddLab = false;
  allLabReports = [];
  appointmentSelectDocumentData = {
    selectDocument: [],
    newDocument: [],
  };
  isPaymentOptionModal = false;
  isPaymentConfirmModal = false;
  isRescheduleAppointmentModal = false;
  paymentOptions = {};
  appointmentIdState = {};
  patientTransectionsDetails = [];
  appointmentConfirmationStatus = [];
  initialSelectedSpeciality = "all";
  bookingAppointmentConfirmationModal = true;
  // state for Loading component
  isLoadingForAllLabReports = true;
  isLoadingAppointmentConfirmationStatus = true;
  isLoadingForUpdateLabReports = false;
  isLoadingDoctorAvailableSlots = true;
  isLoadingDoctorsList = true;
  isLoadingDoctorsProfile = false;
  isModalOpen = false;
  isLoadingForAppointmentList = true;
  isLoadingForPatientAppointmentDetails = true;
  loadingForUploadDocumentForPrescription = false;
  isLoadingForUpdateAppointment = false;
  isLoadingForPatientTransectionsDetails = true;
  isLoadingForCreatOrderPaypal = false;

  // state for handel css ex- active case
  isNextButtonEnable = false; // for steps next button in book appointment
  isAppointmentIdForActive = null; // for active slected slots
  isDoctorIdForLoading = ""; // for loading on selected button -find doctor

  constructor() {
    makeAutoObservable(this);
  }

  getDoctorsBySpecifiedSpeciality = async (specialities) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, error } = await postRequest({ url: GET_DOCTOR_BY_SPECIALITY, payload: specialities });

        const { data, error: err } = getValidDataFromResponse({ response });

        if (!err && isValidArray(data)) {
          this.setAllSpecialityDoctors(data);
          return resolve({ success: true, data });
        }
        this.setAllSpecialityDoctors();
        return resolve({ success: true, data });
      } catch (error) {
        console.log("🚀 ~ PatientStore ~ returnnewPromise ~ error:", error);
        this.setAllSpecialityDoctors();
        return resolve({ success: false, data: [] });
      }
    });
  };

  getDoctorsDetailForPatient = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setisDoctorIdForLoading(doctorId);
        const { response } = await getRequest({ url: `${GET_DOCTOR_DETAILS_DOCTOR_ID}/${doctorId}` });

        const { data, error } = getValidDataFromResponse({ response });

        if (!error && isValidArray(data)) {
          this.setDoctorProfileDataForPatient(data);
          this.setisDoctorIdForLoading();
          return resolve({ success: true, data });
        }

        this.setDoctorProfileDataForPatient();
        this.setisDoctorIdForLoading();
        return resolve({ success: false, data: [] });
      } catch (error) {
        console.log("getDoctorProfileData", error.message);
        this.setisDoctorIdForLoading();
        return reject({ success: false, data: [] });
      }
    });
  };

  getDoctorsAvailableSlots = async (doctorId, selectedDate = { date: moment().format("DD-MM-YYYY") }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, error } = await postRequest({
          url: `${GET_DOCTOR_AVAILABLE_SLOTS}/${doctorId}`,
          payload: selectedDate,
        });
        const { data, error: err } = getValidDataFromResponse({ response });

        if (!err && isValidArray(data)) {
          const [{ doctorId, availableTimings, availability }] = data;

          const newAvailability = [];

          const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.ss");

          for (const slot of availableTimings) {
            const { time } = slot;
            const [startTime] = time.split("-");

            const localStartTime = moment.utc(startTime, "hh:mm A").format("HH:mm:ss.ss");

            const newSelectedDate = moment(selectedDate.date, "DD-MM-YYYY").format("YYYY-MM-DD");

            const timeString = `${newSelectedDate}T${localStartTime}`;

            if (moment(timeString, "YYYY-MM-DDTHH:mm:ss.ss").isBefore(currentTime)) continue;
            newAvailability.push(slot);
          }
          const filteredData = [{ doctorId, availability, availableTimings: newAvailability }];
          this.setDoctorAvailableSlots(filteredData);
          return resolve({ success: true, data: filteredData });
        }
        this.setDoctorAvailableSlots();
        return resolve({ success: true, data: [{}] });
      } catch (error) {
        console.log("error getDoctorsAvailableSlots", error);
        this.setDoctorAvailableSlots();
        return resolve({ success: false, data: [{}] });
      }
    });
  };

  getDoctorDiagnosisQueestion = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({ url: `${GET_DIAGNOSIS_QUESTIONS}/${doctorId}` });
        const { data, error } = getValidDataFromResponse({ response });

        if (!error && isValidArray(data)) {
          this.setIsDoctorDiagnosisQuestion(data);
          return resolve({ success: true, data });
        }
        this.setIsDoctorDiagnosisQuestion();

        return resolve({ success: true, data: [{}] });
      } catch (error) {
        console.log("error", error);
        this.setIsDoctorDiagnosisQuestion();
        return resolve({ success: false, data: [{}] });
      }
    });
  };

  getUrlForUploadedDocument = async (patientId, fileData) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(fileData, "sdkfjk");
        const { response } = await postRequest({
          url: `${GET_URL_FOR_UPLOADADE_DOCUMENT}/${patientId}`,
          payload: fileData,
        });
        const { data, error } = getValidDataFromResponse({ response });
        if (!error && isValidArray(data)) {
          return resolve({ success: true, data });
        }
        return resolve({ success: true, data: [] });
      } catch (error) {
        console.log("error", error);
        return resolve({ success: false, data: [] });
      }
    });
  };

  // upload test report for prescriptions
  uploadTestReports = async (patientId, appointmentId, fileData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, success } = await postRequest({
          url: `${UPLOAD_TEST_REPORTS_BY_PATIENT}/${patientId}/${appointmentId}`,
          payload: fileData,
        });
        const { data, error } = getValidDataFromResponse({ response, isRequired: true });
        if (!error && isValidArray(data)) {
          return resolve({ success: true, data });
        }
        return resolve({ success: true, data: [] });
      } catch (error) {
        console.log("error", error);
        return resolve({ success: false, data: [] });
      }
    });
  };
  getUploadTestReportsForPrescriptions = async (patientId, appointmentId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, success } = await getRequest({
          url: `${GET_UPLOADED_TEST_REPORTS_FOR_PRESCRIPTION}/${patientId}/${appointmentId}`,
        });
        const { data, error } = getValidDataFromResponse({ response });
        if (!error && isValidArray(data)) {
          this.setUploadedTestReportsForPrescriptins(data);
          return resolve({ success: true, data });
        }
        this.setUploadedTestReportsForPrescriptins();
        return resolve({ success: true, data: [] });
      } catch (error) {
        console.log("error", error);
        this.setUploadedTestReportsForPrescriptins();
        return resolve({ success: false, data: [] });
      }
    });
  };
  getAppointmentFeesDetails = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({ url: `${GET_APPOINTMNET_FEES_DETAILS}/${doctorId}` });
        const { data, error: err } = getValidDataFromResponse({ response });

        if (!err && isValidArray(data)) {
          this.setAppointmentFeesDetails(data);
          return resolve({ success: true, data });
        }
        this.setAppointmentFeesDetails();
      } catch (error) {
        console.log("error", error);
        return resolve({ success: false, data: [{}] });
      }
    });
  };

  bookAnAppointment = async (patientId, doctorId, appointmentData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await postRequest({
          url: `${BOOKING_AN_APPTIMENT}/${patientId}/${doctorId}`,
          payload: appointmentData,
        });
        const { data, error: err } = getValidDataFromResponse({ response, isWarning: true });

        if (!err && isValidArray(data)) {
          this.setAppointmentBookingConfermation(data);
          return resolve({ success: true, data });
        }
        this.setAppointmentBookingConfermation();
        return resolve({ success: false, data: [{}] });
      } catch (error) {
        console.log("error", error);
        return resolve({ success: false, data: [{}] });
      }
    });
  };

  // Reschedule Appointment
  UpdateAppointmentSlot = async (patientId, doctorId, appointmentId, appointmentData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await patchRequest({
          url: `${UPDATE_APPOINTMENT_SLOT}/${patientId}/${doctorId}/${appointmentId}`,
          payload: appointmentData,
        });
        const { data, error: err } = getValidDataFromResponse({ response, isRequired: true });

        if (!err && isValidArray(data)) {
          // this.setAppointmentBookingConfermation(data);

          return resolve({ success: true, data });
        }
        // this.setAppointmentBookingConfermation();
      } catch (error) {
        console.log("error", error);
        return resolve({ success: false, data: [{}] });
      }
    });
  };

  // Cancel Appointment
  cancelAppointmentByPatient = async (patientId, appointmentId, isDoctor) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await patchRequest({
          url: `${CANCEL_APPOINTMENT_SLOT_BY_PATIENT}/${patientId}/${appointmentId}`,
          payload: {
            is_cancel: true,
            isDoctor: isDoctor,
          },
        });
        const { data, error: err } = getValidDataFromResponse({ response, isRequired: true });

        if (!err) {
          return resolve({ success: true, data });
        }
        return resolve({ success: false, data });
      } catch (error) {
        console.log("error", error);
        return resolve({ success: false, data: [{}] });
      }
    });
  };

  // Appointment list for patient
  getPatientAppointmenList = async (patientId, type, date = "") => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({
          url: `${GET_APPOINTMENT_LIST}/${patientId}?type=${type}&date=${date ? date : ""}`,
        });
        const { data, error: err } = getValidDataFromResponse({ response });

        if (!err && isValidArray(data) && type === "upcoming") {
          this.setListOfAppointmentUpcoming(data);
          return resolve({ success: true, data: data });
        }
        if (!err && isValidArray(data) && type === "past") {
          this.setListOfAppointmentPast(data);
          return resolve({ success: true, data: data });
        }
        this.setListOfAppointmentPast();
        this.setListOfAppointmentUpcoming();
        return resolve({ success: false, data: [] });
      } catch (error) {
        console.log("🚀 ~ PatientStore ~ returnnewPromise ~ error:", error);
        this.setListOfAppointmentPast();
        this.setListOfAppointmentUpcoming();
        return resolve({ success: false, data: [] });
      }
    });
  };

  // View Patient Appointment details

  getAppointmentDetails = async (patientId, appointmentId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({
          url: `${GET_APPOINTMENT_DETAILS}/${patientId}/${appointmentId}`,
        });
        const { data, error: err } = getValidDataFromResponse({ response });

        if (!err && isValidArray(data)) {
          this.setPatientAppointmentDetails(data);
          return resolve({ success: true, data: data });
        }
        this.setPatientAppointmentDetails([{}]);
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPatientAppointmentDetails([{}]);
        return resolve({ success: false, data: [] });
      }
    });
  };

  getDoctorDetailsByDoctorId = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({
          url: `${GET_DOCTOR_DETAILS_DOCTOR_ID}/${doctorId}`,
        });
        const { data, error } = getValidDataFromResponse({ response });

        if (!error && isValidArray(data)) {
          this.setDoctorDetails(data);
          return resolve({ success: true, data: data });
        }

        this.setDoctorDetails();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPatientAppointmentDetails([{}]);
        return resolve({ success: false, data: [] });
      }
    });
  };

  // add lab reports

  getLabReports = async (pateientId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({
          url: `${GET_ALL_LAB_REPORTS}/${pateientId}`,
        });
        const { data, error } = getValidDataFromResponse({ response });

        if (!error && isValidArray(data)) {
          this.setAllLabReports(data);
          return resolve({ success: true, data: data });
        }

        this.setAllLabReports();
        return resolve({ success: false, data: [] });
      } catch (error) {
        // this.setPatientAppointmentDetails([{}]);
        return resolve({ success: false, data: [] });
      }
    });
  };
  updateLabReports = async (pateientId, labData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await postRequest({
          url: `${GET_UPDATE_LAB_REPORTS}/${pateientId}`,
          payload: labData,
        });
        const { data, error } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && isValidArray(data)) {
          // this.setAllLabReports(data);
          return resolve({ success: true, data: data });
        }

        // this.setAllLabReports();
        return resolve({ success: false, data: [] });
      } catch (error) {
        console.log("🚀 ~ PatientStore ~ async ~ error:", error);
        return resolve({ success: false, data: [] });
      }
    });
  };
  deleteLabReports = async (reportId, patientId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await patchRequest({
          url: `${PATIENT_DELETE_LAB_REPORTS}/${reportId}/${patientId}`,
        });
        const { data, error } = getValidDataFromResponse({ response, isRequired: true });

        if (!error) {
          // this.setAllLabReports(data);
          return resolve({ success: true, data: data });
        }

        // this.setAllLabReports();
        return resolve({ success: false, data: data });
      } catch (error) {
        console.log("🚀 ~ PatientStore ~ async ~ error:", error);
        return resolve({ success: false, data: "Failed to Delete Lab Report" });
      }
    });
  };

  // get payment option provided by Admin
  getPaymentOptionList = (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_PAYMENT_PARTNERS}/${doctorId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidObject(data)) {
          this.setPaymentOptions(data);
          return resolve({ success: true, data: data });
        }
        this.setPaymentOptions();
        return resolve({ success: false, data: {} });
      } catch (error) {
        this.setPaymentOptions();
        return reject({ success: false, data: {} });
      }
    });
  };

  //Patient transections history

  getPatientTransectionsDetails = async (patientId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_PAYMENT_HISTORY}/${patientId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setPatientTransectionsDetails(data);
          return resolve({ success: true, data: data });
        }
        this.setPatientTransectionsDetails();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPatientTransectionsDetails();
        return reject({ success: false, data: [] });
      }
    });
  };

  // get appointment payment status information

  getAppointmentConfirmationStatus = async (appointmentId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await getRequest({
          url: `${GET_APPOINTMENT_CONFIRMATION_STATUS}/${appointmentId}`,
        });
        const { data, error } = getValidDataFromResponse({ response });

        if (!error && isValidArray(data)) {
          this.setAppointmentConfirmationStatus(data);
          return resolve({ success: true, data: data });
        }

        this.setAppointmentConfirmationStatus();
        return resolve({ success: false, data: [] });
      } catch (error) {
        // this.setPatientAppointmentDetails([{}]);
        return resolve({ success: false, data: [] });
      }
    });
  };

  UpdateAppointmentStatusZero = async (appointmentId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response } = await patchRequest({
          url: `${UPDATE_APPOINTMENT_CONFIRM_STATUS_ZERO}/${appointmentId}`,
        });
        const { data, error } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        console.log("🚀 ~ PatientStore ~ async ~ error:", error);
        return resolve({ success: false, data: [] });
      }
    });
  };

  // set state for API response data

  setAllSpecialityDoctors = (data = []) => {
    runInAction(() => {
      this.allSpecialityDoctors = data;
    });
  };

  setDoctorProfileDataForPatient = (data = []) => {
    runInAction(() => {
      this.doctorProfileDataForPatient = data;
    });
  };

  setDoctorAvailableSlots = (data = [{}]) => {
    runInAction(() => {
      this.doctorAvailableSlots = data;
    });
  };

  setIsSelectedDateByCalender = (data = moment()) => {
    runInAction(() => {
      this.isSelectedDateByCalender = data;
    });
  };

  setIsDoctorDiagnosisQuestion = (data = [{}]) => {
    runInAction(() => {
      this.isDoctorDiagnosisQuestion = data;
    });
  };

  setIsAppointmentSteps = (value = { steps: 0, isSuccess: false }) => {
    runInAction(() => {
      this.isAppointmentSteps = value;
    });
  };
  setGetSlotsByTime = (value = "getSlotsByTime") => {
    runInAction(() => {
      this.getSlotsByTime = value;
    });
  };

  setIsAppointmentFormData = (data = this.isAppointmentFormData) => {
    runInAction(() => {
      this.isAppointmentFormData = data;
    });
  };
  setAppointmentFeesDetails = (data = [{}]) => {
    runInAction(() => {
      this.appointmentFeesDetails = data;
    });
  };
  setAppointmentBookingConfermation = (data = [{}]) => {
    runInAction(() => {
      this.appointmentBookingConfermation = data;
    });
  };
  setListOfAppointmentUpcoming = (data = []) => {
    runInAction(() => {
      this.listOfAppointmentUpcoming = data;
    });
  };
  setListOfAppointmentPast = (data = []) => {
    runInAction(() => {
      this.listOfAppointmentPast = data;
    });
  };
  setPatientAppointmentDetails = (data = [{}]) => {
    runInAction(() => {
      this.patientAppointmentDetails = data;
    });
  };
  setIsuploadedTestReportsFileList = (data = []) => {
    runInAction(() => {
      this.isuploadedTestReportsFileList = data;
    });
  };
  setUploadedTestReportsForPrescriptins = (data = []) => {
    runInAction(() => {
      this.UploadedTestReportsForPrescriptins = data;
    });
  };
  setAllLabReports = (data = []) => {
    runInAction(() => {
      this.allLabReports = data;
    });
  };
  setAppointmentSelectDocumentData = (data = {}) => {
    runInAction(() => {
      this.appointmentSelectDocumentData = data;
    });
  };
  setIsOpenModalForPriscriptionDocument = (data = false) => {
    runInAction(() => {
      this.isOpenModalForPriscriptionDocument = data;
    });
  };
  setIsOpenModalForAddLab = (data = false) => {
    runInAction(() => {
      this.isOpenModalForAddLab = data;
    });
  };
  setIsPaymentOptionModal = (data = false) => {
    runInAction(() => {
      this.isPaymentOptionModal = data;
    });
  };
  setIsPaymentConfirmModal = (data = false) => {
    runInAction(() => {
      this.isPaymentConfirmModal = data;
    });
  };
  setIsRescheduleAppointmentModal = (data = false) => {
    runInAction(() => {
      this.isRescheduleAppointmentModal = data;
    });
  };
  setPaymentOptions = (data = {}) => {
    runInAction(() => {
      this.paymentOptions = data;
    });
  };
  setPatientTransectionsDetails = (data = []) => {
    runInAction(() => {
      this.patientTransectionsDetails = data;
    });
  };
  setAppointmentConfirmationStatus = (data = []) => {
    runInAction(() => {
      this.appointmentConfirmationStatus = data;
    });
  };

  setAppointmentIdState = (data = {}) => {
    runInAction(() => {
      this.appointmentIdState = data;
    });
  };

  setInitialSelectedSpeciality = (data = "all") => {
    runInAction(() => {
      this.initialSelectedSpeciality = data;
    });
  };
  setBookingAppointmentConfirmationModal = (isOpen = false) => {
    runInAction(() => {
      this.bookingAppointmentConfirmationModal = isOpen;
    });
  };

  // set state for loading

  setIsLoadingDoctorsList = (value = false) => {
    runInAction(() => {
      this.isLoadingDoctorsList = value;
    });
  };
  setIsLoadingDoctorsProfile = (value = false) => {
    runInAction(() => {
      this.isLoadingDoctorsProfile = value;
    });
  };
  setisDoctorIdForLoading = (value = "") => {
    runInAction(() => {
      this.isDoctorIdForLoading = value;
    });
  };
  setIsModalOpen = (value = false) => {
    runInAction(() => {
      this.isModalOpen = value;
    });
  };
  setIsAppointmentIdForActive = (value = null) => {
    runInAction(() => {
      this.isAppointmentIdForActive = value;
    });
  };
  setIsLoadingForAppointmentList = (value = false) => {
    runInAction(() => {
      this.isLoadingForAppointmentList = value;
    });
  };
  setIsLoadingForPatientAppointmentDetails = (value = false) => {
    runInAction(() => {
      this.isLoadingForPatientAppointmentDetails = value;
    });
  };
  setIsLoadingDoctorAvailableSlots = (value = false) => {
    runInAction(() => {
      this.isLoadingDoctorAvailableSlots = value;
    });
  };

  setisNextButtonEnable = (value = false) => {
    runInAction(() => {
      this.isNextButtonEnable = value;
    });
  };

  setIsConfirmingBooking = (value = false) => {
    runInAction(() => {
      this.isConfirmingBooking = value;
    });
  };
  setIsLoadingForAllLabReports = (value = false) => {
    runInAction(() => {
      this.isLoadingForAllLabReports = value;
    });
  };
  setIsLoadingForUpdateLabReports = (value = false) => {
    runInAction(() => {
      this.isLoadingForUpdateLabReports = value;
    });
  };

  setDoctorDetails = (data = []) => {
    runInAction(() => {
      this.doctorDetails = data;
    });
  };
  setLoadingForUploadDocumentForPrescription = (data = false) => {
    runInAction(() => {
      this.loadingForUploadDocumentForPrescription = data;
    });
  };

  setDefaultDate = (date = moment()) => {
    runInAction(() => {
      this.defaultDate = date;
    });
  };
  setIsLoadingForUpdateAppointment = (date = false) => {
    runInAction(() => {
      this.isLoadingForUpdateAppointment = date;
    });
  };
  setIsLoadingForPatientTransectionsDetails = (date = false) => {
    runInAction(() => {
      this.isLoadingForPatientTransectionsDetails = date;
    });
  };
  setIsLoadingForCreatOrderPaypal = (loading = false) => {
    runInAction(() => {
      this.isLoadingForCreatOrderPaypal = loading;
    });
  };
  setIsLoadingAppointmentConfirmationStatus = (loading = false) => {
    runInAction(() => {
      this.isLoadingAppointmentConfirmationStatus = loading;
    });
  };
}
export default PatientStore;
