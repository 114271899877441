import { EyeOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Popconfirm, Row, Space, Table, Tag, Tooltip } from "antd";
import Loading from "components/shared-components/Loading";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";

const PatientDashboard = () => {
  const { patientDashboardStore } = useMainStore();
  const navigate = useNavigate();
  const [hardRefresh, setHardRefresh] = useState(null);
  const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

  useEffect(() => {
    const { setIsLoadingDashboardData, getPatientStatistics, getDashboardAppointList, getResentPrescriptionList } = patientDashboardStore;

    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

    setIsLoadingDashboardData(true);
    getPatientStatistics(patientId)
      .then(() => {
        getDashboardAppointList({ patientId, limit: 5 });
      })
      .catch(({ message }) => console.log(message))
      .finally(() => setIsLoadingDashboardData());
  }, []);

  const rowClassName = ({ time, is_completed, is_canceled }, index) => {
    const [startTime, endTime] = time.split("-");
    const utcStartTime = moment.utc(startTime, "hh:mm A").local().format("hh:mm A");
    const utcendTime = moment.utc(endTime, "hh:mm A").local().format("hh:mm A");
    const currentTime = moment();
    const activeTag = moment(utcStartTime, "hh:mm A").isAfter(currentTime) || moment(utcendTime, "hh:mm A").isAfter(currentTime);
    const inBetweenTime = currentTime.isBetween(moment(utcStartTime, "hh:mm A"), moment(utcendTime, "hh:mm A"));

    if (activeTag && !is_completed && !is_canceled) {
      return "";
    }
    return "bg-gray-lighter";
  };

  const columns = [
    {
      title: t("Appointment Time"),
      dataIndex: "time",
      key: "time",
      render: (_, { time, is_completed, is_canceled }, ind) => {
        const [startTime, endTime] = time.split("-");
        const utcStartTime = moment.utc(startTime, "hh:mm A").local().format("hh:mm A");
        const utcEndTime = moment.utc(endTime, "hh:mm A").local().format("hh:mm A");
        const currentTime = moment();
        const activeTag = moment(utcStartTime, "hh:mm A").isAfter(currentTime) || moment(utcEndTime, "hh:mm A").isAfter(currentTime);
        const inBetweenTime = currentTime.isBetween(moment(utcStartTime, "hh:mm A"), moment(utcEndTime, "hh:mm A"));

        return (
          <Tag color={activeTag && !is_completed && !is_canceled ? "processing" : "default"} className={`mb-0 text-capitalize `}>
            {utcStartTime + " - " + utcEndTime}
          </Tag>
        );
      },
    },
    {
      title: t("Doctor"),
      dataIndex: "doctor_name",
      key: "doctor_name",
      render: (_, { doctorDetails, time, is_completed }, ind) => {
        const [startTime, endTime] = time.split("-");
        const currentTime = moment();
        const activeTag = moment(startTime, "hh:mm A").isAfter(currentTime) || moment(endTime, "hh:mm A").isAfter(currentTime);
        return (
          <div className="d-flex align-items-center" key={ind}>
            <h4 className={`mb-0 ml-2  text-capitalize`}>{doctorDetails.name}</h4>
          </div>
        );
      },
    },
    {
      title: t("Experience"),
      dataIndex: "experience",
      key: "experience",
      render: (_, { doctorDetails }, ind) => (
        <p key={ind} className="mb-0 text-gray">
          {doctorDetails.experience}
        </p>
      ),
    },
    {
      title: t("Mobile No."),
      dataIndex: "mobile_no",
      key: "mobile_no",
      render: (_, { doctorDetails }, ind) => (
        <p key={ind} className="mb-0 text-gray">
          {doctorDetails.mobile_no}
        </p>
      ),
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      render: (_, { doctorDetails }, ind) => (
        <p key={ind} className="mb-0 text-gray">
          {doctorDetails.email}
        </p>
      ),
    },
    {
      title: t("Type"),
      dataIndex: "appointmentType",
      key: "appointmentType",
      render: (_, { appointmentType }, ind) => (
        <p key={ind} className="text-capitalize m-0">
          {appointmentType}
        </p>
      ),
    },
    {
      title: t("Appointment Mode"),
      dataIndex: "appointmentMode",
      key: "appointmentMode",
      render: (_, { appointmentMode }, ind) => (
        <p key={ind} className="text-capitalize m-0">
          {appointmentMode}
        </p>
      ),
    },
    {
      title: t("Payment Status"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (_, render, ind) => (
        <div className="">
          <Tag
            color={`${
              render.paymentStatus == "paid" || render.paymentStatus == "COMPLETED" || render.paymentMode == "offline" ? "#6FCE3F" : render.paymentStatus == "pending" ? "#FBBF45" : "#FF6D6D"
            }`}
            key={ind}
          >
            {render.paymentStatus == "paid" || render.paymentStatus == "COMPLETED" ? t("PAID") : render.paymentMode == "offline" ? "PAY IN CLINIC" : "PENDING"}
          </Tag>
        </div>
      ),
    },
    {
      title: t("Appointment Status"),
      key: "is_completed",
      dataIndex: "is_completed",
      render: (_, { is_completed, is_canceled }) => (
        <>
          <Tag color={`${is_completed ? "#6FCE3F" : is_canceled ? "#FF6B72" : "#FBBF45"}`} key={is_completed}>
            {is_completed ? t("COMPLETED") : is_canceled ? t("CANCELED") : t("PENDING")}
          </Tag>
        </>
      ),
    },
    {
      title: <p className="text-center text-dark mb-0 font-weight-bold">{t("Actions")}</p>,
      key: "action",
      render: (_, { appointment_id, is_completed, is_canceled, paymentStatus, appointmentMode }, ind) => {
        return (
          <Space size="middle" className="d-flex justify-content-center" key={ind}>
            <Tooltip placement="top" title={t("View Appointment")}>
              <Button className="tabel_icon" type="primary" ghost>
                <Link
                  to={"/patient/view-appointment"}
                  state={{
                    appointment_id,
                    patient_id: patientId,
                    type: "upcoming",
                  }}
                >
                  <EyeOutlined />
                </Link>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title={appointmentMode === "offline" ? "" : is_completed ? "" : t("Join Appointment")}>
              <Popconfirm
                title="Are you sure you want to join?"
                description="Open Popconfirm with Promise"
                placement="topRight"
                onConfirm={() => {
                  navigate("/video-consultation", {
                    state: {
                      userType: 1,
                      appointmentId: appointment_id,
                      patientId,
                    },
                  });
                }}
                disabled={appointmentMode === "offline" ? true : is_completed ? true : is_canceled ? true : false}
                okText="Yes"
                cancelText="No"
              >
                <Button className="tabel_icon" type="primary" disabled={appointmentMode === "offline" ? true : is_completed ? true : is_canceled ? true : false}>
                  <VideoCameraOutlined />
                </Button>
              </Popconfirm>
            </Tooltip>
            {/* )} */}
          </Space>
        );
      },
    },
  ];

  return (
    <Observer>
      {() => {
        const { patientStatistics, isLoadingDashboardData, dashboardAppointmentList, resentPrescriptionList } = patientDashboardStore;
        const [{ appointmentsCount }] = patientStatistics;

        if (isLoadingDashboardData) {
          return <Loading />;
        }

        return (
          <>
            <Row justify="space-between">
              <div>
                <h2>{t("Dashboard")}</h2>
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("/patient/find-doctor");
                  }}
                >
                  {t("Book Appointment")}
                </Button>
              </div>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="d-flex justify-content-between mb-2"></div>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Card title={t("Total Appointments")}>
                      <div>
                        <div className="d-flex align-items-center">
                          <h1 className="mb-0 font-weight-bold">{appointmentsCount}</h1>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <div className="d-flex justify-content-between mb-2">
                  <h2 className="font-size-lg mb-0">{t("Today's Appointments")}</h2>
                  <Button className="book_appointment" type="primary" ghost>
                    <Link to={"/patient/appointments?type=upcoming"}>{t("See All")}</Link>
                  </Button>
                </div>
                <Card style={{ background: "#fff", padding: "0px" }} className="d-flex flex-column justify-content-between">
                  {isValidArray(dashboardAppointmentList) ? (
                    <Table
                      columns={columns}
                      dataSource={dashboardAppointmentList.map((item, ind) => ({
                        ...item,
                        key: ind,
                      }))}
                      pagination={false}
                      rowClassName={rowClassName}
                    />
                  ) : (
                    <Empty description={t("No Appointments for today")} />
                  )}
                </Card>
              </Col>
            </Row>

            {/* <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <div className="d-flex justify-content-between mb-2">
                                    <h2 className="font-size-lg mb-0">Recent Prescriptions</h2>
                                    <Button className="book_appointment" type="primary" ghost>
                                        <Link to={"/patient/prescription-list"}>See All</Link>
                                    </Button>
                                </div>
                                <Card
                                    style={{ background: "#fff", padding: "0px" }}
                                    className="d-flex flex-column justify-content-between"
                                >
                                    {isValidArray(resentPrescriptionList) ? (
                                        <Table
                                            columns={prescriptionsColumns}
                                            dataSource={resentPrescriptionList.map((item, ind) => ({
                                                ...item,
                                                key: ind,
                                            }))}
                                            pagination={false}
                                        />
                                    ) : (
                                        <Empty description="No recent prescriptions" />
                                    )}
                                </Card>
                            </Col>
                        </Row> */}
          </>
        );
      }}
    </Observer>
  );
};

export default PatientDashboard;
