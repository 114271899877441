import { FileDoneOutlined, HomeOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Empty, Form, Radio, Row, Space, Tag } from "antd";
import GoBack from "components/shared-components/GoBack";
import { Icon } from "components/util-components/Icon";
import { DATE_FORMAT_DD_MMM_YYYY } from "constants/DateConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment, useEffect } from "react";
import { FaSquare } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getLocalStorageItems, isValidArray, isValidObject } from "utils/utilities";

const slotsOptions = [
  {
    label: "Morning",
    value: "morningSlots",
  },
  {
    label: "Afternoon",
    value: "afterNoonSlots",
  },
  {
    label: "Evening",
    value: "eveningSlots",
    title: "eveningSlots",
  },
  {
    label: "Night",
    value: "nightSlots",
  },
];
const RescheduleAppointment = () => {
  const { patientStore } = useMainStore();
  const [slotForm] = Form.useForm();
  const location = useLocation();
  const { appointmentId, doctorId } = location.state;
  const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });
  const navigate = useNavigate();

  useEffect(() => {
    const { getDoctorsAvailableSlots, setIsLoadingDoctorAvailableSlots, setIsAppointmentIdForActive, setIsLoadingDoctorsProfile, getDoctorsDetailForPatient } = patientStore;
    const selectedDate = { date: moment().format("DD-MM-YYYY") };

    // fetch doctor details start **************
    setIsLoadingDoctorsProfile(true);
    getDoctorsDetailForPatient(doctorId).finally(() => setIsLoadingDoctorsProfile());
    // fetch doctor details end **************

    // fetch available slots start
    setIsAppointmentIdForActive(null);
    setIsLoadingDoctorAvailableSlots(true);
    getDoctorsAvailableSlots(doctorId, selectedDate).finally(() => setIsLoadingDoctorAvailableSlots(false));
  }, []);

  const rescheduleSlot = async () => {
    try {
      const { UpdateAppointmentSlot, setIsLoadingForUpdateAppointment } = patientStore;
      await slotForm
        .validateFields()
        .then(({ date, time }) => {
          const appointmentData = {
            date: moment(date).format("DD-MM-YYYY"),
            time,
          };

          setIsLoadingForUpdateAppointment(true);
          UpdateAppointmentSlot(patientId, doctorId, appointmentId, appointmentData).then(({ data, success }) => {
            if (success) setIsLoadingForUpdateAppointment();
            navigate("/patient/view-appointment", {
              state: {
                appointment_id: appointmentId,
                patient_id: patientId,
                type: "upcoming",
              },
            });
          });
        })
        .finally(() => {
          setIsLoadingForUpdateAppointment();
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log("🚀 ~ rescheduleSlot ~ error:", error);
    }
  };

  const onChange = (value) => {
    const { getDoctorsAvailableSlots, setIsLoadingDoctorAvailableSlots, setIsSelectedDateByCalender, isSelectedDateByCalender } = patientStore;
    setIsSelectedDateByCalender(value);
    setIsLoadingDoctorAvailableSlots(true);
    const selectedDate = { date: moment(value).format("DD-MM-YYYY") };
    getDoctorsAvailableSlots(doctorId, selectedDate).finally(() => setIsLoadingDoctorAvailableSlots(false));
  };

  const getSlotOption = (value) => {
    const { setGetSlotsByTime } = patientStore;
    setGetSlotsByTime(value.target.value);
  };
  const disabledDate = (current) => {
    const currentDate = new Date();
    return (current && current.year() > currentDate.getFullYear()) || current < currentDate.setHours(0, 0, 0, 0);
  };
  return (
    <>
      <Observer>
        {() => {
          const {
            isAppointmentIdForActive,
            doctorAvailableSlots,
            isLoadingDoctorAvailableSlots,
            isSelectedDateByCalender,
            setIsAppointmentIdForActive,
            getSlotsByTime,
            isAppointmentFormData,
            isLoadingDoctorsProfile,
            doctorProfileDataForPatient,
            isLoadingForUpdateAppointment,
          } = patientStore;

          const [{ doctorProfile, speciality, degrees, clinicInfo, fees }] = doctorProfileDataForPatient;
          const [{ availableTimings, availability }] = doctorAvailableSlots;

          return (
            <>
              <Row align="middle">
                <GoBack />
                <h2 className="ml-1">{t("Reschedule Appointment")}</h2>
              </Row>
              <Row className="mt-3" gutter={[16, 16]}>
                <Col xxl={6} xl={6} lg={7} md={8} span={24}>
                  <Card loading={isLoadingDoctorsProfile} className="appointment_profile">
                    <div className="">
                      <div className="text-center pb-4">
                        <img src={`${doctorProfile?.profile_pic ? doctorProfile?.profile_pic : "/img/avatars/thumb-1.jpg"}`} width={100} height={100} className="rounded" alt="" />
                        <h3 className="mt-3">{doctorProfile?.name}</h3>
                        <h4 className="text-primary mb-0 text-capitalize">
                          {degrees?.map(({ degree_name }) => {
                            return <>{degree_name.toUpperCase()} </>;
                          })}
                        </h4>
                      </div>
                      <hr />

                      <div className="py-4">
                        <p className="d-flex align-items-center">
                          <Icon type={PhoneOutlined} className="text-primary font-size-md mr-3" />
                          {doctorProfile?.mobile_no}
                        </p>
                        <p className="d-flex align-items-start">
                          <Icon type={HomeOutlined} className="text-primary font-size-md mr-3 mt-1" />
                          {capitalizeFirstLetter(clinicInfo?.city)}
                        </p>
                        <p className="d-flex align-items-center">
                          <Icon type={UserOutlined} className="text-primary font-size-md mr-3" />
                          {capitalizeFirstLetter(doctorProfile?.gender)}
                        </p>
                        <p className="d-flex align-items-center mb-0">
                          <Icon type={FileDoneOutlined} className="text-primary font-size-md mr-3" />
                          {`${doctorProfile?.experience} Year`}
                        </p>
                      </div>
                      <hr />
                      <h3 className="mt-3 mb-0 ">{t("Speciality")}</h3>
                      {speciality?.map(({ speciality_name }) => {
                        return (
                          <Tag color="blue" className="mt-3 text-capitalize">
                            {speciality_name}
                          </Tag>
                        );
                      })}
                    </div>
                  </Card>
                </Col>
                <Col xxl={18} xl={18} lg={17} md={16} span={24}>
                  <Form name="slotForm" form={slotForm} layout="vertical">
                    <Card
                      bodyStyle={{
                        maxHeight: "550px",
                        overflowY: "scroll",
                      }}
                      className="slot_card"
                    >
                      <div className="d-flex justify-content-between">
                        <h2>{isSelectedDateByCalender.format(DATE_FORMAT_DD_MMM_YYYY)}</h2>
                        <div>
                          <Form.Item name={"date"} label={t("Select Time Slot")}>
                            <DatePicker onChange={onChange} allowClear={false} disabledDate={disabledDate} defaultValue={moment()} format={"DD-MM-YYYY"} />
                          </Form.Item>
                        </div>
                      </div>
                      <>
                        <div className="d-flex justify-content-between align-items-xl-center align-items-start my-3">
                          <div className="d-xl-flex align-items-center">
                            <span className="d-flex align-items-center mr-3 mb-xl-0 mb-2">
                              <FaSquare size={20} color="#FAFAFA" className="border mr-2" />
                              <span>{t("Available")}</span>
                            </span>
                            <span className="d-flex align-items-center mr-3 mb-xl-0 mb-2">
                              <FaSquare size={20} color="#3E79F7" className="border mr-2" />
                              <span>{t("Selected")}</span>
                            </span>
                            <span className="d-flex align-items-center">
                              <FaSquare size={20} color="#9DAECF" className="border mr-2" />
                              <span>{t("Already Booked")}</span>
                            </span>
                          </div>
                          <Form.Item className="mb-0">
                            <Radio.Group size="small" options={slotsOptions} required={true} onChange={getSlotOption} name={"slotOption"} value={getSlotsByTime} className="slot_wrapper"></Radio.Group>
                          </Form.Item>
                        </div>
                        {isValidObject(availableTimings) && isValidArray(availableTimings[getSlotsByTime]) ? (
                          <>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                              name={"time"}
                              label={t("Select Time Slot")}
                            >
                              <Radio.Group name="time" onChange={(val) => setIsAppointmentIdForActive(val.target.id)}>
                                <Space wrap>
                                  {availableTimings[getSlotsByTime]?.map(({ time, isBooked }, _ind) => {
                                    const [startTime, endTime] = time?.split("-");
                                    return (
                                      <Fragment key={_ind}>
                                        <Radio className="book_appointment_radio" value={time} disabled={isBooked} id={_ind}>
                                          <Tag
                                            color={_ind == isAppointmentIdForActive && !isBooked ? "#3F68F3" : isBooked ? "#9DAECF" : ""}
                                            disabled={isBooked}
                                            size={"large"}
                                            className={`${!isBooked ? "cursor_pointer" : ""} px-4 py-1 w-100`}
                                          >
                                            <span className="mr-2">{startTime.trim()}</span>
                                            <span>-</span>
                                            <span className="ml-2">{endTime.trim()}</span>
                                          </Tag>
                                        </Radio>
                                      </Fragment>
                                    );
                                  })}
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          </>
                        ) : (
                          <Empty description={t("No slots for this day cycle")} />
                        )}
                      </>
                    </Card>
                  </Form>
                </Col>
              </Row>
              <Row align="bottom">
                <Col span={24} className="d-flex justify-content-end">
                  <Button type="primary" onClick={rescheduleSlot} loading={isLoadingForUpdateAppointment}>
                    {t("Update Slot")}
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
      </Observer>
    </>
  );
};

export default RescheduleAppointment;
