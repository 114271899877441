import { ConfigProvider } from "antd";
import { resources } from "lang";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import useBodyClass from "utils/hooks/useBodyClass";
import VerifyAccount from "views/auth-views/components/VerifyAccount";
import ForgetPassword from "views/auth-views/forget-password";
import Login from "views/auth-views/login";
import ResetPasswordForm from "views/auth-views/reset-password";
import SignIn from "views/auth-views/signIn";
import DoctorViews from "views/doctor-views";
import DoctorAppointments from "views/doctor-views/appointments";
import DefaultDashboard from "views/doctor-views/dashboard";
import DoctorPayouts from "views/doctor-views/payouts";
import PrescribeReport from "views/doctor-views/prescribe-report";
import DoctorSettings from "views/doctor-views/profile";
import DoctorTransactions from "views/doctor-views/transactions-history";
import AppointmentViewForDoctor from "views/doctor-views/view-appointment";
import NotFoundPage from "views/not-found/404Page";
import PatientViews from "views/patient-views";
import PatientAppointment from "views/patient-views/appointments";
import RescheduleAppointment from "views/patient-views/appointments/RescheduleAppointment";
import ViewPatientAppointment from "views/patient-views/appointments/ViewAppointment";
import BookAppointment from "views/patient-views/book-an-appointment";
import SalesDashboard from "views/patient-views/dashboard";
import FindDoctors from "views/patient-views/find-doctor";
import AddLabReports from "views/patient-views/lab-reports";
import PatientPrescriptionList from "views/patient-views/prescription-list";
import PrescriptionDetails from "views/patient-views/prescription-list/PrescriptionDetails";
import PatientProfile from "views/patient-views/profile";
import PatientTransactions from "views/patient-views/transactions-history";
import PrivacyPolicy from "views/polices/PrivacyPolicy";
import TermsNConditions from "views/polices/TermsNConditions";
import VideoConsultation from "views/videoConference";
import ProtectedRoute from "./ProtectedRoute";
// import PrescribeModal from "views/doctor-views/prescribe-report/AddPrescribeModal";

const PaymentSuccess = React.lazy(() => import("components/payment/PaymentSuccess"));
const PaymentFailed = React.lazy(() => import("components/payment/PaymentFailed"));

const AppRoutes = () => {
  const { locale, direction } = useSelector((state) => state.theme);
  const currentAppLocale = resources[locale];
  useBodyClass(`dir-${direction}`);
  useEffect(() => {
    if (window === undefined) {
      return;
    }
    const root = window.document.documentElement;
    root.setAttribute("dir", direction);
  }, [direction]);
  return (
    <ConfigProvider direction={direction} locale={currentAppLocale.antd}>
      <Routes>
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
        <Route path="/verify-user" element={<VerifyAccount />} />
        <Route path="/tnc" element={<TermsNConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route
          path="/video-consultation"
          element={
            <ProtectedRoute>
              <VideoConsultation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-failed"
          element={
            <ProtectedRoute>
              <PaymentFailed />
            </ProtectedRoute>
          }
        />

        {/* <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}> */}
        <Route
          path="/doctor"
          element={
            <ProtectedRoute>
              <DoctorViews />
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <DefaultDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="appointments"
            element={
              <ProtectedRoute>
                <DoctorAppointments />
              </ProtectedRoute>
            }
          />
          <Route
            path="add-prescription"
            element={
              <ProtectedRoute>
                <PrescribeReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="view-appointment"
            element={
              <ProtectedRoute>
                <AppointmentViewForDoctor />
              </ProtectedRoute>
            }
          />
          <Route
            path="transactions"
            element={
              <ProtectedRoute>
                <DoctorTransactions />
              </ProtectedRoute>
            }
          />
          <Route
            path="payouts"
            element={
              <ProtectedRoute>
                <DoctorPayouts />
              </ProtectedRoute>
            }
          />
          <Route
            path="setting/*"
            element={
              <ProtectedRoute>
                <DoctorSettings />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/patient"
          element={
            <ProtectedRoute>
              <PatientViews />
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <SalesDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <PatientProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="find-doctor"
            element={
              <ProtectedRoute>
                <FindDoctors />
              </ProtectedRoute>
            }
          />
          <Route
            path="book-appointment"
            element={
              <ProtectedRoute>
                <BookAppointment />
              </ProtectedRoute>
            }
          />
          <Route
            path="reschedule-appointment"
            element={
              <ProtectedRoute>
                <RescheduleAppointment />
              </ProtectedRoute>
            }
          />
          <Route
            path="transactions"
            element={
              <ProtectedRoute>
                <PatientTransactions />
              </ProtectedRoute>
            }
          />
          <Route path="appointments" element={<PatientAppointment />} />
          <Route path="view-appointment" element={<ViewPatientAppointment />} />
          <Route path="prescription-list" element={<PatientPrescriptionList />} />
          <Route path="prescription-details" element={<PrescriptionDetails />} />
          <Route path="lab-reports" element={<AddLabReports />} />
        </Route>
        {/* </Route> */}

        <Route path="*" element={<Navigate to={`/not-found`} />} />
      </Routes>
    </ConfigProvider>
  );
};

export default AppRoutes;
