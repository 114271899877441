// NavNotification.js

import { BellOutlined, CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Empty, List, Popover } from "antd";
import { DATE_FORMAT_DD_MMM_YYYY } from "constants/DateConstant";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageItems } from "utils/utilities";
import { initializeSocket } from "./socketListener";

const NavNotification = () => {
  const location = useLocation();
  const urlUserType = location.pathname.split("/")[1];
  const [data, setData] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { globalStore } = useMainStore();
  const navigate = useNavigate();

  const { getNotifications, readNotification } = globalStore;
  const { user_id, userType } = getLocalStorageItems({ getAll: true, keys: ["user_id", "userType"] });

  useEffect(() => {
    const cleanup = initializeSocket(getNotifications, user_id, userType, (newData) => setData(newData));

    return cleanup;
  }, []);

  const handleNotificationClick = async (item, redirect) => {
    console.log("🚀 ~ handleNotificationClick ~ item:", item);
    try {
      const response = await readNotification(item._id, urlUserType);
      if (response && redirect) {
        setPopoverVisible(false);
        const url = urlUserType === "doctor" ? "/doctor/view-appointment" : "/patient/view-appointment";
        const isPastDate = moment.utc(item.created_at).isBefore(moment.utc());
        console.log("🚀 ~ handleNotificationClick ~ isPastDate:", isPastDate);
        navigate(url, {
          state: {
            ...(urlUserType === "doctor"
              ? { appointmentId: item.appointmentId, patientId: item.patientId, type: isPastDate ? "past" : "upcoming" }
              : { appointment_id: item.appointmentId, patient_id: item.patientId, type: isPastDate ? "past" : "upcoming" }),
            openModal: false,
          },
        });
      }
    } catch (error) {
      console.error("Error reading notification:", error);
    }
  };
  const notificationList = (
    <div className="notification-container">
      <div className="nav-notification-header">
        <h4 className="mb-0">Notification</h4>
      </div>
      <div className="notification-list-wrapper">
        {data.length > 0 ? (
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item className={`list-clickable ${item["read_by_" + urlUserType] === true ? "read-notification" : ""}`} onClick={() => handleNotificationClick(item, true)}>
                <List.Item.Meta
                  avatar={
                    <div
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <CalendarOutlined style={{ fontSize: "1.25rem" }} />
                    </div>
                  }
                  title={item["admin_title"]}
                  description={moment.utc(item.created_at).local().format(`${DATE_FORMAT_DD_MMM_YYYY}, dddd`)}
                />
                <CloseOutlined
                  className="notification-close-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from triggering the List.Item click event
                    handleNotificationClick(item, false); // Function to handle notification dismissal
                  }}
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty description="You have viewed all notifications" />
        )}
      </div>
    </div>
  );
  return (
    <Popover placement="bottomRight" title={null} content={notificationList} trigger="click" overlayClassName="nav-notification" visible={popoverVisible} onVisibleChange={setPopoverVisible}>
      <div className="nav-item">
        <Badge count={data.length}>
          <BellOutlined className="nav-icon mx-auto" type="bell" />
        </Badge>
      </div>
    </Popover>
  );
};

export default NavNotification;
